/** @jsx jsx */

import type { ReactElement } from 'react';
import { jsx } from '@balance-web/core';
import { useTheme } from '@balance-web/theme';
import { forwardRefWithAs, useId } from '@balance-web/utils';

import type { ModalSize } from './size';
import { useModalWidth } from './size';
import { DialogBase } from './DialogBase';

type ContentDialogProps = {
  children: React.ReactNode;
  footer?: ReactElement;
  header?: ReactElement;
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  size?: ModalSize;
  autoFocus?: boolean;
  showSectionDivider?: boolean;
};

export const ContentDialog = forwardRefWithAs<'div', ContentDialogProps>(
  (
    {
      children,
      footer,
      header,
      id,
      isOpen,
      onClose,
      size = 'large',
      autoFocus = false,
      showSectionDivider = true,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const instanceId = useId(id);

    const modalWidth = useModalWidth(size);
    const borderStyle = `1px solid ${theme.palette.global.border}`;

    return (
      <DialogBase
        id={instanceId}
        isOpen={isOpen}
        onClose={onClose}
        width={modalWidth}
        ref={ref}
        enforceLayout
        elevation="modal"
        autoFocus={autoFocus}
        {...props}
      >
        {header && (
          <div
            css={
              showSectionDivider && {
                borderBottom: borderStyle,
                '@media print': { borderBottom: '0px' },
              }
            }
          >
            {header}
          </div>
        )}

        <div
          className="dialog-content"
          css={{
            flex: 1,
            overflowY: 'auto',
            '@media print': { overflowY: 'visible' },
          }}
        >
          {children}
        </div>

        {footer && (
          <div
            css={
              showSectionDivider && {
                borderTop: borderStyle,
                '@media print': { borderTop: '0px' },
              }
            }
          >
            {footer}
          </div>
        )}
      </DialogBase>
    );
  }
);
