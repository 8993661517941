/** @jsx jsx */

import { jsx } from '@balance-web/core';
import { forwardRefWithAs } from '@balance-web/utils';

import { DialogBase } from './DialogBase';
import type { DialogBaseProps } from './DialogBase';

// NOTE: ideally this wouldn't be necessary. It could be resolved with a
// significant refactor, where the `ContentDialog` accepts `header/body/footer |
// children` and layout is enforced when `body` is present in props.

type BlankDialogProps = DialogBaseProps & {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  autoFocus?: boolean;
};

/** @deprecated The BlankDialog component has been deprecated and will be removed in future versions. Use the SideDrawer package instead.  */
export const BlankDialog = forwardRefWithAs<'div', BlankDialogProps>(
  ({ children, autoFocus = false, ...props }, ref) => {
    return (
      <DialogBase ref={ref} autoFocus={autoFocus} elevation="modal" {...props}>
        {children}
      </DialogBase>
    );
  }
);
